* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

.container6 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure container takes full viewport height */
}

.content6 {
  flex-grow: 1; /* Content expands to fill space, pushing footer down */
  width: 100%;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 48px;
}

.card-grid6 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
  gap: 40px;
}

.card6 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card6 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card6 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

/* Footer Styling */
.footer6 {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .card-grid6 {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    margin-left: 50px;
  }
}

@media (max-width: 768px) {
  .card-grid6 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet screens */
    gap: 30px;
    margin-left: 30px;
  }

  .card6 {
    width: 100%; /* Full width for each card */
    height: auto;
  }

  h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .card-grid6 {
    grid-template-columns: 1fr; /* 1 column for mobile view */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px;
    gap: 20px;
  }

  .card6 {
    width: 100%; /* Full width for mobile */
    height: auto;
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align image and text */
    justify-content: center;
    text-align: center; /* Center text */
    padding: 20px;
  }

  .card6 img {
    width: 70px; /* Adjust image size for better fit */
    height: 70px;
    margin-bottom: 10px; /* Add space below the image */
  }

  .card6 p {
    font-size: 0.85em; /* Adjust font size for better readability on small screens */
    margin: 0;
    margin-left: -15px;
  }

  h1 {
    font-size: 1.5em;
  }
}

