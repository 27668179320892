* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

.container3 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container covers the entire height of the viewport */
  font-family: Arial, sans-serif;
}

.content3 {
  flex-grow: 1; /* Ensures the content area grows and pushes the footer to the bottom */
  width: 95%;
}

.content3 h1 {
  text-align: center;
  margin-top: 10px;

}

.card-grid3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
  gap: 40px;
  margin-top: 20px;
}

.card3 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 220px;
}

.card3 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card3 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

/* Responsive Footer */
/* .footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
} */

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .card-grid3 {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    margin-left: 0;
  }

  h1 {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .card-grid3 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet-sized screens */
    gap: 30px;
  }

  .card3 {
    width: 100%; /* Cards take full width in each grid column */
    padding: 20px;
    height: auto;
  }

  h1 {
    margin-left: 25px;
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .card-grid3 {
    grid-template-columns: 1fr; /* 1 column layout for mobile */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 20px;
    gap: 20px;
  }

  .card3 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align the content */
    justify-content: center;
    text-align: center; /* Center the text */
    padding: 20px;
  }

  .card3 img {
    width: 60px; /* Adjust image size for mobile */
    height: 60px;
    margin-bottom: 10px; /* Add space between image and text */
  }

  .card3 p {
    font-size: 0.85rem;
    margin-top: 10px;
    margin-left:-20px; /* Add spacing for better readability */
  }

  .contetn3 h1 {
    font-size: 1.5em; /* Smaller font size for the heading on small screens */

  }
}
