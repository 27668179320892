.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  padding: 5px;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto; 
}

.clients-section {
  flex: 2;
  width: 100%;
}

.clients-section h2 {
  text-align: center;
  color: #1A237E;
  border-bottom: 1px solid rgb(207, 206, 206);
  font-size: 1.5rem;
  margin-top: 45px;
  margin-left: 20px; /* Fix typo: add 'px' for margin-left */
}

.logo-grid-wrapper {
  display: flex;
  justify-content: space-between;
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(4, 0.3fr); /* Four columns initially */
  gap: 10px;
  margin-left: 30px;
  margin-right: 0; 
  width: 60%; 
}

.logo-item img {
  width: 80%; 
  height: 80px !important; 
  margin-top: 20px; 
  border: 1px solid #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4); 
}

.logo-item img:hover {
  border-color: #1A237E;
}

.facebook-widget-section {
  flex: 1;
  margin-left: 750px;
  padding: 10px;
  margin-top: -430px;
  height: 100%;
  width: 500px; /* Increased width */
}

.facebook-widget-section iframe {
  width: 350px; /* Increased iframe width to match the section */
  height: 500px; /* Adjust height if necessary */
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

/* Media query for tablets and smaller devices */
@media (max-width: 1024px) {
  .logo-grid-wrapper {
    flex-direction: column; 
  }

  .logo-grid {
    width: 100%;
  }

  .facebook-widget-section {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}

/* Media query for tablets */
@media (max-width: 768px) {
  .clients-section {
    width: 100%;
  }
  .clients-section h2{
    margin-left: 10px;
  }

  .logo-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller tablets */
    gap: 10px;
  }

  .facebook-widget-section {
    margin-left: 15px;
    margin-bottom: 0px;
    padding: 10px;
  }

  .facebook-widget-section iframe {
    width: 100%;
    height: auto;
  }
}

/* Media query for phone view */
@media (max-width: 480px) {
  .clients-section h2 {
    font-size: 1.2rem;
  }

  .logo-grid {
    grid-template-columns: repeat(2, 1fr); /* Stacks all cards vertically */
    gap: 5px;
    width: 100%;
    margin-left: 20px; /* Make the grid full width */
  }

  .logo-item img {
    width: 80%; /* Make the images take up the full width of the card */
    height: 70px !important; /* Reduce the height for phone view */
  }

  .facebook-widget-section iframe {
    width: 300px;
    height: 500px;
  }
}
