.sidebar {
  width: 100%;
  font-weight: 700;
  background-color: #039be5;
  padding: 20px;
  height: 100vh;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 20px 0;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  font-size: 0.95rem;
  display: block;
}

.sidebar-link:hover {
  color: #a6e1ff;
}

/* Hamburger Menu (Mobile View) */
.hamburger {
  display: none; /* Hidden on desktop */
  background-color: #039be5;
  color: white;
  font-size: 1.5rem;
  border: none;
  width: 100%; /* Full width */
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    padding: 10px;
    position: absolute;
    top: 0;
    left: -100%; /* Hide sidebar */
    transition: left 0.3s ease;
  }

  .sidebar.open {
    left: 0; /* Show sidebar */
  }

  .sidebar ul {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .sidebar li {
    margin: 10px 0;
  }

  .hamburger {
    display: block; /* Show hamburger on mobile */
  }
}