.apply-job-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1400px; /* Increased the max width */
    margin: 0 auto;
    padding: 20px;
    background-color: #eee;
    border-radius: 8px;
    font-family: Gupter, serif;
    margin-bottom: 20px;
}

.apply-job-form img {
    width: 40%; /* Reduced the width of the image */
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-top: 60px;
    border: 1px solid black;
}

.apply-job-form form {
    width: 55%; /* Increased the width of the form */
}

.apply-job-form h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #039be5;
    text-align: left;
    border-bottom: 1px solid rgb(203, 194, 194);
    width: 100%;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.form-group {
    width: 48%;
    margin-bottom: 15px;
}

.form-group label {
    font-size: 1em;
    display: block;
    margin-bottom: 5px;
    color: #000;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: Gupter, serif;
}

.form-group textarea {
    resize: vertical;
}

.form-group input[type="file"] {
    padding: 5px;
}

.form-group span {
    color: red;
}

.submit-btn {
    font-family: Gupter, serif;
    background-color: #039be5;
    color: white;
    padding: 10px 15px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 95%;
    max-width: 200px;
    margin: 10px 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.submit-btn:hover {
    background-color: #1A237E;
}

@media (max-width: 768px) {
    .apply-job-form {
        flex-direction: column;
    }

    .form-group {
        width: 100%;
    }

    .apply-job-form img {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .submit-btn {
        width: 100%;
    }
}
