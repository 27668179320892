
* {
  margin: 0;
  padding: 0;
}


.container-cards {
  height: 100%;
  width: 100%;
  background-color: #eee;
}


.back-img {
  background-size: cover;
  background-position: center;
}


.logo-grid1 {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
  gap: 10px; 
  margin: 20px auto;
}

.logo-item1 img {
  width: 75%;
  height: 100px !important;
  margin-top: 50px;
  border: 2px solid #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  transition: border-color 0.3s ease;
}


.logo-item1 img:hover {
  border-color: #1a237e;
}


.poster {
  width: 100%;
  height: auto;
  opacity: 0.95;
}

.poster img {
  width: 100%;
  margin: 10px auto;
  height: 250px;
}

/* Responsive design with media queries */

/* Medium screens (tablets, smaller laptops) */
@media (max-width: 1024px) {
  .logo-grid1 {
    grid-template-columns: repeat(4, 1fr); /* 4 columns on medium screens */
  }

  .logo-item1 img {
    width: 80%; /* Slightly larger for medium screens */
    height: 90px !important; /* Adjust height */
  }

  .poster img {
    height: 200px; /* Smaller poster for medium screens */
  }
}

/* Small screens (mobile devices) */
@media (max-width: 768px) {
  .logo-grid1 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
    gap: 5px; /* Smaller gap */
  }

  .logo-item1 img {
    width: 90%; /* Increase size for better visibility */
    height: 80px !important;
    margin-top: 20px; /* Reduce margin */
  }

  .poster img {
    height: 150px; /* Smaller poster for mobile */
  }
}

/* Very small screens (small mobile devices) */
@media (max-width: 480px) {
  .logo-grid1 {
    grid-template-columns: repeat(2, 1fr); /* 1 column on very small screens */
  }

  .logo-item1 img {
    width: 80%; /* Full width for very small screens */
    height: 70px !important;
    margin-top: 10px;
    margin-left: 20px; /* Reduce margin further */
  }

  .poster img {
    height: 120px; /* Smaller poster for small devices */
  }
}
