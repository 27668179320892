.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px; 
  background-color: white;
  color: #1A237E;
  font-family: Gupter, serif;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  height: 80px; /* Adjusted height */
}

.navbar-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-logo img {
  height: 70px; /* Adjusted height */
  background-color: transparent;
  margin: 0 15px; 
  width: 80%; /* Adjusted width */
  transition: transform 0.3s ease;
}

.navbar-logo img:hover {
  transform: scale(1.1); 
}

.logo-text {
  font-size: 0.6rem; /* Smaller text size */
  margin-top: -1px; /* Space between image and text */
  color: black;
  text-align: center;
}

.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.navbar-links li a {
  color: #1A237E;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 700;
  margin-right: 20px;
}

.navbar-links li a:hover {
  color: #039be5;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.social-icon {
  color: #1A237E;
  font-size: 1.2rem;
  transition: all 0.5s ease;
}

.social-icon:hover{
  transform: translateY(5px);
}

.mobile-menu-icon {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #1A237E;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    background-color: #f0f0f0;
    height: auto; /* Adjust for smaller screens */
  }

  .navbar-logo img {
    height: 40px;
    width: 75px;
  }
  .logo-text{
    font-size: 0.5rem;
  }
  .navbar-links {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 10;
  }

  .navbar-links.active {
    display: flex;
    padding: 20px;
  }

  .navbar-links li a {
    font-size: 0.85rem;
    margin: 15px 0;
  }

  .mobile-menu-icon {
    display: block;
  }

  .footer-social {
    display: none;
  }
}
