* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.content1 {
  width: 100%;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  margin-left: 0;
}

.card-grid1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding-top: 20px;
}

.card1 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card1 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card1 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

@media (max-width: 1024px) {
  .card-grid1 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .card1 {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
    margin-left: 0;
  }
  
  .container {
    margin-top: -5px;
  }

  .card-grid1 {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .card1 {
    padding: 15px;
    flex-direction: column;
    height: auto;
    align-items: center;
    text-align: center;
  }

  .card1 img {
    width: 80px;
    height: 60px;
    margin-bottom: 10px;
  }

  .card1 p {
    font-size: 0.85em;
    margin-left: -20px;
  }
}
