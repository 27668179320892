.technology-slider {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.slider-title {
  color: #1a237e;
  text-decoration: underline;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 30px;
}

.slick-track {
  display: flex;
  animation: slide-left 30s linear infinite;
  width: max-content;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  opacity: 1;
  flex-shrink: 0;
  /* width: 200px; */
}

.slide-item img{
  width: 180px;
}

.tech-logo {
  max-width: 100%;
  max-height: 100px;
  padding: 7px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.tech-logo:hover {
  transform: scale(1.05);
}

.slide-item p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

/* Phone view media query: Display 2 cards at a time */
@media (max-width: 480px) {
  .technology-slider {
    width: 100%;
    padding: 10px;
  }

  .slider-title {
    font-size: 16px; /* Reduced size for better visibility on mobile */
    margin-bottom: 20px;
  }

  .slick-track {
    width: auto; /* Ensure the track is wide enough to slide */
  }

  .slide-item {
    width: 45%; /* Ensures two cards fit side by side */
    height: auto; /* Maintain card aspect ratio */
  }

  .slide-item img{
    width: 200%;
    max-width: 130%;
  }

  .tech-logo {
    /* max-width: 100%;
    max-height: 100px; */
    padding: 5px;
  }

  .slide-item p {
    font-size: 12px;
  }
}
