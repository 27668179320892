.container {
    display: flex; /* Enable flexbox for centering */
    flex-direction: row; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    margin: 0 auto; /* Center the container itself */
    width: 100%; /* Full width */
    max-width: 1200px; /* Optional: Limit the max width */
  }
  
  .content {
    width: 100%;
    height: auto; /* Changed to auto for flexibility */
    margin: 25px 0; /* Vertical margin */
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: hidden; 
    font-family: Gupter, serif;
  }
  
  .Aboutback-imgs {
    /* flex: 1;  */
    width: 100%;
    display: flex;
    /* margin-left: 5%; */
    align-items: center; 
  }
  
  .Aboutback-imgs img {
    width: 100%;
    height: auto; 
    height: 100%;
    box-shadow: 0 0 5px;
    margin-right: 10px;
  }
  
  .Aboutcontent-write {
    /* flex: 1.5;  */
    width: 100%; /* Adjusted to avoid extra width */
    margin: 0 10px;
    text-align: justify;
    text-align: center; 
  }
  
  .Aboutcontent-write h3 {
    color: black;
    font-weight: 700;
    margin-bottom: -15px;
    padding-bottom: 5px;
  }
  
  .Aboutcontent-write p {
    font-size: 16px; 
    padding: 0 8%;
    text-align: justify;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    hyphens: auto; 
    max-width: 100%; 
    line-height: 20px;
    color: black;
    font-size: 16px;
    margin-top: 15px;
    width: 100%;
  }
  
  .Aboutbtn-contentt {
    margin: 20px 25px;
    height: 40px;
    width: 100px;
    background-color: #26b9c9;
    color: white;
    border-radius: 4px;
    border: none;
    margin-left: 9px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  
  .Aboutbtn-contentt:hover {
    background-color: white;
    color: #039be5;
    border: 3px solid black;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .content {
        flex-direction: column;
        align-items: center;
        height: auto;
    }
  
    .Aboutback-imgs {
        width: 80%;
        margin-bottom: 10px;
    }
  
    .Aboutcontent-write {
        width: 80%; /* Adjusted to fit within the screen */
        padding-left: 5px;
    }
  
    .Aboutbtn-contentt {
        width: 100%; /* Make button full width on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .back-imgs img {
        height: 120px;
        margin-left: -10px;
        margin-top: 20px;
    }
    .content{
      background-color: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      margin-top: 20px;
    }
  
    .Aboutcontent-write h3 {
        font-size: 1.2rem;
    }
  
    .Aboutcontent-write p {
        font-size: 14px; /* Keep the font size */
        width: 110%; /* Ensure full width of the parent container */
        max-width: 600px; /* Increase the maximum width to your desired value */
        text-align: justify; /* Justify the text */
        padding: 5px 5px; /* Adjust padding as needed */
        margin-left: -15px; /* Reset left margin */
        margin-right: 0; /* Reset right margin */
        line-height: 1.2; /* Decrease line height */ /* Include padding in width calculation */
    }
  
    .Aboutbtn-contentt {
        width: 100%; /* Full width for button on small screens */
        margin-top: 10px;
        margin-left: 0; /* Reset margin */
    }
  }
  
  /* Keyframes for image and text animations */
  @keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
  }
  
  @keyframes slide-in-right {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
  }
  
  /* Animating elements */
  .Aboutback-imgs img {
    animation: slide-in-left 1s ease-out forwards;
  }
  
  .Aboutcontent-write {
    animation: slide-in-right 1s ease-out forwards;
    animation-delay: 0.5s; /* Delay to make the paragraph appear slightly after the image */
  }
  
  
  .Aboutbtn-contentt {
    animation: slide-in-right 1s ease-out forwards;
    animation-delay: 1s; /* Delay to make the button appear after the paragraph */
  }
  
  .Aboutbtn-contentt:hover {
    background-color: white;
    color: #039be5;
    border-radius: 10px;
    border: 3px solid black;
  }
  
  .long-text{
    height: 0;
    overflow: hidden;
    display:inline-block;
  }
  
  .expanded{
    max-height:10rpm;
  }
  