.grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80%; /* Two-column layout */
  gap: 10px;
  align-items: start;
  justify-content: space-between;
}

/* Responsive Styles */

/* For tablets (screen width ≤ 1024px) */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr; /* Single column layout for tablets */
  }
}

/* For mobile devices (screen width ≤ 768px) */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    gap: 5px; /* Reduce gap for smaller screens */
  }
}
