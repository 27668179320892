.about-container {
  padding: 50px 20px;
  background-color: #F9F9F9;
}
.about-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
}
.about-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four cards in one row */
  gap: 20px;
}
.about-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}
.about-card:hover {
  transform: translateY(-10px);
}
.card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #1A237E;
}
.about-card-content {
  padding: 20px;
  text-align: center;
}
.about-card-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1A237E;
  margin-bottom: 15px;
}
.about-card-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  word-spacing: 0.1em;
}
/* Adjust text size for smaller screens */
@media (max-width: 768px) {
  .about-card-content h3 {
    font-size: 1.25rem;
  }
  .about-card-content p {
    font-size: 0.9rem;
  }
}
/* Make cards fit on smaller screens */
@media (max-width: 768px) {
  .about-cards {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row on smaller screens */
  }
}
/* For very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .about-container {
    padding: 20px;
  }
  .about-cards {
    grid-template-columns: 1fr; /* One card per row on mobile screens */
  }
  .about-card-content h3 {
    font-size: 1.1rem;
  }
  .about-card-content p {
    font-size: 0.85rem;
  }
}
.about-card:last-child {
  grid-column: auto; /* No special treatment for the last card */
}
/* Adjust for tablet screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .about-cards {
    grid-template-columns: repeat(3, 1fr); /* Three cards per row on tablets */
  }
}