.footer {
  background-color: #F5F5F5;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.footer-contact p {
  margin: 5px 0;
  color:#031218;
}

.footer-contact a {
  text-decoration: none;
 
}

.footer-contact a:hover {
  text-decoration: underline;
}
.footer-content {
  flex-grow: 1;
  padding: 0 20px;
}

.footer-logo img {
  width: 100%;
  height: 80px;
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.1); 
}

.footer-content p {
  margin: 5px 0;
  color: #093982;
}

.footer-icons a {
  color: #093982;
  font-size: 1.2rem;
  margin: 0 10px;
  text-decoration: none;
}

.footer-icons a:hover {
  color: #b57900; 
}

@media (min-width: 1200px) {
  .footer-container {
    flex-direction: row;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    text-align: left; 
  }
}


@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo,
  .footer-content,
  .footer-icons {
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .footer-icons a {
    font-size: 1.2rem; 
  }

  .footer-content p {
    font-size: 0.9rem; 
  }

  .footer-logo img {
    height: 60px; 
  }
}
