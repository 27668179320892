* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container4 {
  display: flex;
  height: auto; 
  flex-direction: column; 
}

.content4 {
  width: 100%; 
  padding: 20px;
}
.content4 h1 {
  text-align: center;
  font-size: 48px;
  margin-top: 0px;
}


.card-grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 40px; 
  margin: 0 auto; 
}


.card4 {
  background: #fff;
  padding: 30px; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 100%; 
}


.card4 img {
  width: 50px; 
  height: 50px;
  margin-right: 20px; 
}


.card4 p {
  font-size: 0.9em; 
  color: #333;
  margin: 0;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .card-grid4 {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
      margin: 0 50px; /* Adjust margins for medium screens */
  }
}

@media (max-width: 768px) {
  .card-grid4 {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
      margin: 0 20px; /* Adjust margins for smaller screens */
  }
}
@media (max-width: 480px) {
  .card-grid4 {
    grid-template-columns: 1fr; /* 1 column for mobile screens */
    margin: 0 10px;
    gap: 20px;
    margin-top: -4px; /* Minimal margin for mobile */
  }

  .card4 {
    width: 100%;
    height: auto;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center the content */
    text-align: center;
    padding: 20px; /* Adjust padding for mobile */
  }

  .card4 p{
    margin-left: -20px;
  }
  .card4 img {
    width: 70px; /* Adjust image size */
    height: 60px;
    margin-bottom: 10px; /* Add space between the image and the text */
  }

  .content4 h1 {
    margin-left: 0;
    font-size: 1.5em;
    margin-top: -3px; /* Adjust heading margin for mobile */
  }
}

