* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.content {
  width: 100%;
  padding: 20px;
}

.content h1 {
  text-align: center;
  font-size: 48px;
  margin: 0 auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
  gap: 40px;
  padding-top: 20px;
}

.card {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

/* Responsive Styles */

/* For tablets (screen width ≤ 1024px) */
@media (max-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 30px;
  }

  .content h1 {
    font-size: 36px; /* Adjust heading size for tablets */
  }
}

/* For mobile devices (screen width ≤ 768px) */
@media (max-width: 768px) {
  .content h1 {
    font-size: 24px; /* Smaller heading for mobile */
  }

  .card-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
    gap: 20px;
  }

  .card {
    width: 100%;
    padding: 15px;
    flex-direction: column; /* Stack image and text in one column */
    height: auto;
    text-align: center;
  }

  .card img {
    width: 80px; /* Adjust image size for mobile */
    height: 60px;
    margin: 0 auto 10px; /* Center image and add spacing below */
  }

  .card p {
    font-size: 0.85em;
  }
}
