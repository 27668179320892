* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.container5 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container covers the entire viewport height */
  font-family: Arial, sans-serif;
  width: 100%; /* Ensures the container width fits the viewport */
}

.content5 {
  flex-grow: 1; /* This allows the content to push the footer down */
  width: 100%; /* Adjusted to 100% to avoid extra width */
  padding: 20px;
}

.container5 h1 {
  text-align: center;
  font-size: 48px;
  margin-top: 0;
}

.card-grid5 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
  gap: 40px;
  padding: 20px;
  margin-top: 20px;
}

.card5 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 100%; /* Adjusted to take full width of the grid column */
  max-width: 210px; /* Maximum width for each card */
}

.card5 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card5 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

/* Footer Styling */
/* .footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
} */

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .card-grid5 {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
  }

  h1 {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .card-grid5 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet screens */
    gap: 20px;
  }

  .card5 {
    width: 100%; /* Cards take full width in each grid column */
    padding: 20px;
    height: auto;
  }

  h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .card-grid5 {
    grid-template-columns: 1fr; /* 1 column layout for mobile */
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -5px;
  }

  .card5 {
    width: 100%;
    max-width: 350px; 
    height: auto;
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center-align the image and text */
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .card5 img {
    width: 60px; /* Adjust image size for mobile */
    height: 60px;
    margin-bottom: 10px; /* Add spacing between image and text */
  }

  .card5 p {
    font-size: 0.85em;
    margin-top: 10px;
    margin-left:-15px;
  }

  .content5 h1 {
    font-size: 1.2em; /* Smaller font size for the heading on small screens */
    margin-bottom: 0;
  }
}
