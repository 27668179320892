* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container8 {
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.content8 p,
.content9 p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.content9 {
  margin-top: 20px;
}

.outline {
  text-decoration: underline;
}

.content8 h1 {
  text-align: center;
  font-size: 48px;
  margin-top: 0;
}

.card-grid8,
.card-grid9 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin-top: 30px;
}

.card8,
.card9 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card8 img,
.card9 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card8 p,
.card9 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

@media (max-width: 1024px) {
  .card-grid8,
  .card-grid9 {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .card-grid8,
  .card-grid9 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .content8 p,
  .content9 p {
    margin-inline-start: 0;
    margin-top: 10px;
  }

  h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .card-grid8,
  .card-grid9 {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .card8,
  .card9 {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .card8 img,
  .card9 img {
    width: 60px;
    height: 60px;
    margin: 0 0 10px 0;
  }

  .content8 h1 {
    font-size: 1.5em;
  }

  .outline p {
    font-size: 1em;
  }
}
