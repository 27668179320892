* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container2 {
  display: flex;
  font-family: Arial, sans-serif;
}  

.card-content-educations {
  width: 100%; 
  padding: 20px;
  display: block;
} 

.card-content-educations h1 {
  text-align: center;
  font-size: 48px;
  margin: 0 auto;
}

.card-content-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 40px; 
  padding-top: 20px;
}

.card2 {
  background: #fff;
  padding: 15px; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card2 img {
  width: 50px; 
  height: 50px;
  margin-right: 20px; 
}

.card2 p {
  font-size: 0.9em; 
  color: #333;
  margin: 0;
}

@media (max-width: 1200px) {
  .card-content-grid {
    grid-template-columns: repeat(3, 1fr); 
    margin-left: 0; 
  }
}

@media (max-width: 768px) {
  .card-content-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 70px; /* 2 cards in a row for smaller screens */
  }

  h1 {
    margin-left: 0;
    font-size: 0.8em; /* Center title on smaller screens */
  }
}

@media (max-width: 480px) {
  .card-content-grid {
    grid-template-columns: 1fr; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    gap: 20px;
  }
  
  .card-content-educations h1 {
    font-size: 1.5em;
  }

  .card2 {
    width: 100%; /* Ensure cards take full width */
    padding: 20px;
    flex-direction: column; /* Stack image and text in one column */
    align-items: center; /* Center image and text */
    text-align: center;
  }

  .card2 img {
    width: 80px; /* Adjust image size for mobile */
    height: 60px;
    margin-bottom: 10px;
    margin-top: -13px; /* Add spacing below image */
  }

  .card2 p {
    font-size: 0.85em;
    margin-left: -20px;
  }
}
