.careers-container {
  padding: 20px;
  font-family: Gupter, serif;
  text-align: left;
}

.careers-header {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.header-image {
  width: 100%;
  height: 350px;
  opacity: 1;
}

.careers-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  font-size: 3rem; /* Adjust for smaller screens in media queries */
  color: rgb(0, 0, 0);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

.careers-content {
  display: flex;
  flex-direction: row; /* Default to row on larger screens */
  justify-content: space-between;
  margin: 20px 0;
}

/* Description styling */
.careers-description {
  width: 60%; /* Adjust for smaller screens in media queries */
  color: #555;
  /* margin-left: 50px; */
}

.careers-description p {
  color: black;
  /* line-height: 1.6; */
  text-align: justify;
}

.careers-description ul {
  list-style-type: disc;
  padding-left: 35px;
  padding-top: 10px;
}

.careers-description ul li {
  color: black;
  margin-bottom: 10px;
}

/* Job positions styling */
.job-box {
  width: 80%; /* Adjust for smaller screens in media queries */
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  height: 120px;
  margin-right: 0; /* Reset for smaller screens */
  margin-top: 10px;
}

.job-positions h2 {
  font-size: 1.5rem;
  color: #039be5;
  border-bottom: 1px solid #ddd;
  margin-right: 250px;
  margin-bottom: 15px;
}

.job-positions ul {
  list-style-type: none;
  padding-left: 0;
}

.job-positions ul li {
  padding-top: 1px;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .careers-title {
    font-size: 2.5rem; /* Slightly smaller on medium screens */
  }

  .careers-content {
    flex-direction: column; /* Stack content on smaller screens */
    align-items: center; /* Center align items */
  }

  .careers-description {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:5px; /* Full width on smaller screens */
  }

  .job-box {
    width: 90%; /* Wider job box on smaller screens */
    margin: 0 auto; /* Center align job box */
  }
}

@media (max-width: 768px) {
  .careers-title {
    font-size: 2rem; /* Even smaller on smaller devices */
  }

  .job-box {
    height: 100%; /* Allow height to adjust */
  }
}

@media (max-width: 480px) {
  .careers-title {
    font-size: 1.8rem; /* Adjust for very small screens */
  }

  .job-box {
    padding: 25px;
    width: 100%;
  }
  .job-positions h2{
    margin-left: 60px;
    margin-right: 65px;
    margin-top: 10px;
  }

  .careers-description p {
    font-size: 0.9rem;
    width: 120%; /* Slightly smaller font size */
  }
  .careers-header img{
    height: 180px;
  }
}
