.portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default for larger screens */
  gap: 30px;
  margin: 20px;
  margin-left: 90px;
  margin-right: 90px;
 
}
.prot-heading{
  margin-top: 20px;
}
.portfolio-card {
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-card:hover {
  transform: scale(1.07);
  border: 2px solid #039be5;
}

.portfolio-image {
  width: 100%;
  height: 200px; 
  object-fit: cover;
}

.portfolio-text {
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}

.popup-heading {
  margin-bottom: 15px; 
  font-size: 1.5em;
  color: black;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.thumbnail-image {
  width: 200px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  background-color: #eee;
}

.thumbnail-image:hover {
  transform: scale(1.07);
  border: 2px solid #039be5;
}

.thumbnail-image.active {
  border-color: #039be5; 
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100vh); 
    opacity: 0;
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
}

.popup-content {
  width: 90vw;
  height: 100vh;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  animation: slideDown 2s ease-in-out;
  opacity: 1; 
}

.popup-image {
  width: 75%;
  height: 80%;
  object-fit: contain;
  margin-top: 15px;
}

.prev-btn, .next-btn {
  background-color: transparent;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.4s ease;
}

.close-btn:hover {
  background-color: #d32f2f;
}

/* New Media Query for Mobile View */
@media (max-width: 480px) {
  .portfolio-container {
    grid-template-columns: repeat(2, 1fr); /* Show 2 cards in mobile view */
    margin-left: 5px; /* Adjust margins for smaller screens */
    margin-right: 5px; /* Adjust margins for smaller screens */
  }
  .popup-heading {
    margin-bottom: 10px;
    font-size: 1em;
    color: black;
  }
  .thumbnail-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    transition: border-color 0.3s ease;
    background-color: #eee;
  }
  .portfolio-image {
    height: 150px; 
    width: 95%;
    object-fit: cover;/* Adjust height for better visibility on small screens */
  }
  .popup-image {
    width: 90%;
    height: 40%;
    object-fit: contain;
    margin-top: 15px;
  }
  .popup-content {
    width: 90vw;
    height: 40vh;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    animation: slideDown 2s ease-in-out;
    opacity: 1;
  }
  .portfolio-text {
    font-size: 1em; /* Slightly reduce font size for better readability */
  }
}
