* {
  margin: 0px;
  padding: 0px;
  font-family: Gupter, serif;
}
body {
  font-family: Gupter, serif;
}
.heading-service h1 {
  text-align: center;
  margin-top: 20px;
  font-size: xx-large;
  font-weight: bolder;
}
h4 {
  text-align: center;
  margin-top: 10px;
  color: #17a2b8;
  font-size: large;
  font-weight: 700;
}
#content {
  max-width: 70%;
  margin: auto;
  margin-top: 20px;
}
#content p{
  color: black;
}
img {
  width: 130px;
}
.cimages {
  width: 100%;
  max-width: 320px;
  height: auto;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.cimages:hover {
  transform: scale(1.1);
}
.orange {
  color: #ff7206;
  text-align: center;
  margin-top: 15px;
}

.blue {
  color: #527fd3;
  text-align: center;
  margin-top: 15px;
}

.red {
  color: #e43d43;
  text-align: center;
  margin-top: 15px;
}

.teal {
  color: #18a7b7;
  text-align: center;
  margin-top: 15px;
}
#content {
  padding: 20px;
  max-width: 80%;
  margin: 0 auto; 
  
}

#para {
  font-size: 1rem;
  line-height: 1.6; 
  color: #333; 
  word-spacing: 0.1em; 
  text-align: justify;

}
.lightblue {
  color: #00aff0;
  text-align: center;
  margin-top: 15px;
}
#images {
  max-width: 70%;
  margin: auto;
  height: 40vh;
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}
#id1 {
  height: 160px;
  width: 160px;
  border: solid 4px orange;
  border-radius: 50%;
}
.orange,
.blue,
.red,
.lightblue,
.teal {
  text-align: center;
  margin-top: 1px;
  font-size: larger;
}
#cimages {
  height: 152px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}
#id2 {
  height: 160px;
  width: 160px;
  border: solid 4px #039be5;
  border-radius: 50%;
}
#cimages {
  height: 152px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}
#id3 {
  height: 160px;
  width: 160px;
  border: solid 4px #e43d43;
  border-radius: 50%;
}
#cimages {
  height: 152px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}
#id4 {
  height: 160px;
  width: 160px;
  border: solid 4px #18a7b7;
  border-radius: 50%;
}
#cimages {
  height: 152px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}
#id5 {
  height: 160px;
  width: 160px;
  border: solid 4px #007bff;
  border-radius: 50%;
}
#cimages {
  height: 152px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}
/* Large Screens (desktops, laptops) */
@media (max-width: 1024px) {
  #images {
    max-width: 90%;
    height: auto;
    flex-wrap: wrap;
    gap:30px /* Allow wrapping for smaller screens */
  }

  .orange,
  .blue,
  .red,
  .teal,
  .lightblue {
    font-size: larger;
  }

  #cimages {
    max-width: 250px; /* Reduce image size on smaller screens */
  }
}
/* Tablets and small laptops (max-width: 768px) */
@media (max-width: 768px) {
  #images {
    flex-direction: column;
    height: auto;
  }

  #id1, #id2, #id3, #id4, #id5 {
    width: 140px;
    height: 140px;
    margin-bottom: 20px; /* Add space between images */
  }

  #cimages {
    width: 120px;
    height: 120px;
  }

  .orange,
  .blue,
  .red,
  .teal,
  .lightblue {
    font-size: medium;
    margin-top: 10px;
  }
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  #images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  #id1, #id2, #id3, #id4, #id5 {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
  }

  #cimages {
    width: 110px;
    height: 110px;
  }

  .orange,
  .blue,
  .red,
  .teal,
  .lightblue {
    font-size: small;
    margin-top: 5px;
  }
}
.lists{
  margin-left: 30px;
}
.content-container-blue {
  background: linear-gradient(to right, #039be5, #a6e1ff);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5% 8%;
  padding: 10px 25px;
}

.content-text-blue {
  width: 65%;
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: right;
}
.content-text-blue h2{
  text-align: center;
  padding: 5px;
}
.content-text-blue p{
  text-align: justify;
  padding-top: 15px;
  color: black;
  line-height: 25px;
}

.btn1{
  color: #039be5;
  width: 17%;
  background-color: #fff;
  margin: 20px 0;
  font-size: 14px;
  border: none;
  padding: 10px 8px;
  border-radius: 6px;
  transition: all 0.5s ease;
}
.btn1:hover {
  background: transparent;
  color: white;
  border: 1px solid black;
  transform: translateX(10px);
  font-weight: bold;
}

.img-blue img{
  width: 240px;
}

.content-container-white {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5% 8%;
  padding: 10px 25px;
}

.content-text-white {
  width: 60%;
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: right;
}
.content-text-white h2{
  text-align: center;
  padding: 5px;
}
.content-text-white p{
  text-align: justify;
  padding-top: 15px;
  color: black;
  line-height: 25px;
}

.btn2 {
  color: #fff;
  background-color: #039be5;
  border: none;
  width: 17%;
  margin: 20px 0;
  padding: 10px 4px;
  border-radius: 6px;
  transition: all 0.5s ease;
}

.btn2:hover {
  background-color: #039be5;
  border: 1px solid black;
  transform: translateX(10px);
  font-weight: bold;
}

.img-white img{
  width: 280px;
  
}
.mobile{
  margin-top: 35px;
}
.imgWhite{
  margin-top: 20px;
}
.imgblue{
  margin-top: 20px;
}

.imgblue img{
  width: 330px;
}

.blueimg {
  margin-top: 20px;
}
.blueimg img{
  width: 250px;
}

.imgWhite-1 img{
  width: 315px;
}
@media (max-width: 1024px) {
  .content-container-blue, .content-container-white {
    flex-direction: column;
    align-items: center;
    margin: 5% 3%;
  }

  .content-text-blue, .content-text-white {
    width: 90%;
    text-align: center;
  }

  .btn1, .btn2 {
    width: 25%;
  }

  .img-blue img, .img-white img {
    width: 220px;
  }

  .imgblue img {
    width: 300px;
  }

  .blueimg img, .imgWhite-1 img {
    width: 240px;
  }
}

/* Small Tablets and Large Phones (max-width: 768px) */
@media (max-width: 768px) {
  .content-container-blue, .content-container-white {
    margin: 5% 2%;
  }

  .content-text-blue, .content-text-white {
    width: 100%;
    text-align: center;
  }

  .btn1, .btn2 {
    width: 35%;
    font-size: 12px;
  }

  .img-blue img, .img-white img, .imgblue img {
    width: 180px;
  }

  .blueimg img, .imgWhite-1 img {
    width: 200px;
  }
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .content-container-blue, .content-container-white {
    padding: 5% 3%;
    margin: 10% 0;
  }
  #content p{
    text-align: justify;
    width: 120%;
    margin-left: -30px;
    font-size: 14px;
  }

  .content-text-blue, .content-text-white {
    width: 100%;
    text-align: center;
  }
  .content-text-blue p, .content-text-white p{
    font-size: 0.85em;
    line-height: 17px;
  }
  .content-text-blue h2, .content-text-white h2{
    font-size: 1em;
  }
  .content-text-blue li, .content-text-white li{
    margin-left: 14px;
    margin-right: 330px;
    font-size: 14px;
    margin-top: 3px;
  }


  .btn1, .btn2 {
    width: 50%;
    font-size: 15px;
    padding: 8px 6px;
    font-weight: 300;
    margin-left: 89px;
  }

  .img-blue img, .img-white img, .imgblue img, .blueimg img, .imgWhite-1 img {
    width: 200px;
  }
  .img-white img{
    width: 280px;
    margin-top: -30px;
    
  }
 
}