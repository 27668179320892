.contact-container {
  display: flex;
  justify-content: center;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  margin: -5px;
  font-family: Gupter, serif;
  flex-wrap: wrap; 
}

.heading h1 {
  text-align: center;
  margin: 15px auto;
}

h1 span {
  color: #039be5;
}

.address-section {
  background-color: #039be5;
  padding: 20px;
  width: 110%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; 
}

.address-heading {
  color: black;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}

.address-section p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: flex-start;
}

.address-section i {
  margin-right: 10px;
  color: #1A237E;
}

.map-section {
  width: 50%;
  margin-left: 20px;
}

.map-section iframe {
  width: 100%;
  height: 200px;
  border: none;
}



/* New Media Queries for Responsive Design */
@media (max-width: 768px) {
  .address-section {
    flex-direction: column;
    align-items: center;
  }

  .map-section {
    width: 100%;
    margin-left: 0;
    margin-top: 20px; /* Space between address and map on mobile */
  }

  .address-section p {
    font-size: 12px; /* Smaller text for mobile screens */
  }

  .map-section iframe {
    height: 250px; /* Slightly larger height for better mobile view */
  }
}

@media (max-width: 480px) {
  .address-section {
    padding: 15px; 
    height: auto;
    width: 100%;/* Reduce padding for smaller screens */
  }
  .address-text{
    text-align: justify;
  }
.heading h1{
  font-size: 180%;
}
  .address-section p {
    font-size: 14px; /* Smaller font size for very small screens */
    margin: 5px 0; /* Reduce margin for better spacing */
  }
 

  .map-section iframe {
    height: 200px; /* Adjust height on smaller screens */
  }
}
