.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1A237E;
  overflow: hidden;
  z-index: 0; 
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
}

.hero-content h1 {
  font-size: 4rem;
  margin: 0;
  color: #1A237E;
}

.hero-content p {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #1A237E;
}

.hero-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%; 
  background-size: cover;
  background-position: center;
  animation: slide 15s infinite;
  opacity: 0;
  z-index: 1;
}

.slide1 {
  /* background-image: url('../assets/img1.jpg'); */
  background-image: url('../assets/human2');
}

.slide2 {
  /* background-image: url('../assets/img2.jpg'); */
  background-image: url('../assets/humman3.jpg');
}

.slide3 {
  /* background-image: url('../assets/img3');  */
  background-image: url('../assets/human1.jpg');
}

.slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.text-slide1, .text-slide2, .text-slide3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  padding: 20px;
}

@keyframes slide {
  0% {
    left: 100%;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  30% {
    left: 0;
    opacity: 1;
  }
  40% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

.slide1 {
  animation-delay: 0s;
}

.slide2 {
  animation-delay: 5s;
}

.slide3 {
  animation-delay: 10s;
}

@keyframes fadeText {
  0%, 33%, 100% {
    opacity: 0;
  }
  10%, 30% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem; 
  }

  .hero-content p {
    font-size: 1.2rem; 
  }

  .text-slide1, .text-slide2, .text-slide3 {
    font-size:0.95rem; 
    padding: 15px;
  }

  .slide {
    height: 400px; 
  }
  .hero-section{
    height: 50vh;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem; 
  }
  .hero-section{
    height: 20vh;
  }

  .text-slide1, .text-slide2, .text-slide3 {
    margin-top: -75px;
    font-size: 0.85rem;
    padding: 10px;
  }

  .slide {
    height: 300px; 
  }
}
