.aboutus {
  padding: 20px;
  font-family: Gupter, serif;
  text-align: left;
}

.about-header {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.about-image {
  width: 100%; 
  height: auto; 
  max-height: 350px; 
  opacity: 0.95;
}
@media (max-width: 768px) {
  .aboutus {
      padding: 15px;
  }

  .about-header h1 { 
      font-size: 24px; 
  }
}

@media (max-width: 480px) {
  .about-header h1 { 
      font-size: 20px; 
  }

  .aboutus p { 
      font-size: 14px; 
      line-height: 1.5;
  }
}
