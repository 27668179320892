body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

.testimonials-section {
  text-align: center;
  padding: 40px;
}

/* Desktop view: 3-column grid */
.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
}

/* Card styling */
.testimonial-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
  height: auto;
  width: 90%;
  margin-top: 10px;
}

.testimonial-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* When hovering, the card moves slightly upwards */
.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* When the card is clicked (active state), add a shadow effect */


/* Responsive adjustments for images */
.national-img,
.hcc-img,
.dor-img,
.moon-img,
.social-img,
.mindscript-img,
.panchshil-img,
.graphix-img,
.repairlogo-img {
  width: 100%;
  max-width: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

.quote-national,
.quote-hcc,
.quote-dor,
.quote-moon,
.quote-social,
.quote-mindscript,
.quote-panchshil {
  font-size: 1rem;
  line-height: 1.4;
  word-wrap: break-word;
  white-space: normal;
}

.testimonial-author {
  font-weight: bold;
  text-align: center;
}

.testimonial-position {
  font-size: 0.9em;
  color: #666;
  text-align: center;
}

/* Media queries for responsiveness */

/* Tablets: Change to a 2-column grid */
@media (max-width: 1024px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .national-img,
  .hcc-img,
  .dor-img,
  .moon-img,
  .social-img,
  .mindscript-img,
  .panchshil-img,
  .graphix-img,
  .repairlogo-img {
    max-width: 120px;
  }
}

/* Mobile: Change to a 1-column grid */
@media (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }

  .testimonial-card {
    width: 100%;
  }

  .national-img,
  .hcc-img,
  .dor-img,
  .moon-img,
  .social-img,
  .mindscript-img,
  .panchshil-img,
  .graphix-img,
  .repairlogo-img {
    max-width: 100px;
  }

  .testimonial-quote {
    font-size: 14px;
  }
}
.panchshil-img,.moon-img,.mindscript-img,.repairlogo-img,.hcc-img,.dor-img{
  width: 100px;
  height: 100px;
}
.quote-social{
  margin-top: 17px;
}
.quote-national{
  margin-top: 4px;
}