.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content1, .content2 {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 50% 50%; /* Two equal columns */
  gap: 10px;
}

.content1 {
  order: 2;
}

.content2 {
  order: 1;
}

.content1 h4, .content2 h4 {
  border-bottom: 1px solid black;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 1.2rem;
}

.back-img1, .back-img2 {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.back-img1 img, .back-img2 img {
  width: 100%; 
  max-width: 80%; /* Limit max width for better responsiveness */
}
.back-img1 img{
  height: 80%;
}

.back-img1 img, .text1 {
  animation: slide-in-left 1s ease-out forwards;
}

.back-img2 img, .text2 {
  animation: slide-in-right 1s ease-out forwards;
}

.text1, .text2 {
  text-align: center;
  padding: 15px;
}

.text1 p, .text2 p {
  text-align: justify;
  margin: 0 auto; /* Ensure the paragraph is centered */
  width: 100%; /* Set to full width */
  max-width: 600px; 
  line-height: 20px;
  color: black;
  font-size: 16px;
  margin-top: 15px;/* Optional: limit the max width for readability */
}

.btn-content, .btn-content2 {
  margin: 20px 25px;
  height: 40px;
  width: 130px;
  background-color: #039be5;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn-content:hover, .btn-content2:hover {
  background-color: white;
  color: #039be5;
  border: 2px solid black;
}

/* Media query for tablet devices */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center; 
  }

  .content1, .content2 {
    width: 100%;
    grid-template-columns: 100%; /* Collapse into a single column */
    margin-bottom: 20px;
    min-height: 200px; /* Set a minimum height for consistency */
  }

  .back-img1, .back-img2 {
    justify-content: center; /* Center the images */
    width: 100%; /* Ensure full width for image container */
  }

  .back-img1 img, .back-img2 img {
    max-width: 100%; 
    height: auto;
  }

  .btn-content, .btn-content2 {
    width: 80%; 
  }
}

/* Media query for phone view */
@media (max-width: 480px) {
  .container {
    flex-direction: column;
  }

  .text2 p {
    text-align: justify;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    max-width: 90%;
    font-size: 14px;
    line-height: 20px;
  }
  
  .text1 p {
    text-align: justify;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    max-width: 98%;
    font-size: 14px;
    line-height: 20px;
  }

  .content1, .content2 {
    display: flex;
    flex-direction: column; /* Stack both content and images vertically */
    align-items: center; /* Center the content */
    width: 100%;
    gap: 20px; 
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
    background-color: #fff; /* Add box shadow */
    min-height: 150px; /* Set a minimum height for consistency */
  }

  .back-img1, .back-img2 {
    justify-content: center; /* Center the images */
    width: 100%; /* Ensure full width for image container */
  }

  .back-img1 img {
    width: 80%; 
    height: auto; /* Ensure the images maintain their aspect ratio */
    margin-top: 15px;
  }
  .back-img2 img{
    width: 90%;
  }

  .content1 h4, .content2 h4 {
    font-size: 1rem; /* Reduce the heading size for small screens */
  }

  .text1, .text2 {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the text */
    align-items: center; /* Horizontally center the text */
    width: 100%;
    padding: 10px; /* Adjust padding for better spacing */
  }

  .btn-content, .btn-content2 {
    width: 100px; 
    font-size: 0.75rem;
  }
}
