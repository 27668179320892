
@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #eee;
  font-family: Gupter, serif;
  
  
  animation: slide-up 0.5s ease-out forwards;
}

.form-container h3 {
  text-align: center;
  color: #333;
}

.dark {
  color: #333;
}

p {
  text-align: center;
  color: #777;
}

.form-group-contact {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}
.submit-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 640px) {
  .form-group-contact {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;

  /* Add animation */
  opacity: 0;
  animation: slide-up 0.5s ease-out forwards;
  animation-delay: 0.2s;
}

textarea {
  resize: vertical;
}

/* Submit button styling */
.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #039be5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  
  /* Add animation */
  opacity: 0;
  animation: slide-up 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

/* Media query for larger screens */
@media (min-width: 640px) {
  .submit-button {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.submit-button:hover {
  background-color: #1A237E;
}
