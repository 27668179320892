* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container7 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes full viewport height */
  font-family: Arial, sans-serif;
  padding: 20px;
}

.content7 {
  flex-grow: 1; /* Allow content to expand and push footer down */
  width: 95%;
}

.content7 h1 {
  text-align: center;
  margin-top: 0;
}

/* Grid layout for larger screens (desktop) */
.card-grid7 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on desktop */
  gap: 50px;
}

.card7 {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100px;
  width: 200px;
}

.card7 img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.card7 p {
  font-size: 0.9em;
  color: #333;
  margin: 0;
}

/* Footer Styling */
.footer6 {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 20px; /* Add margin if needed */
}

/* Responsive adjustments */

/* For tablets (screen width <= 1024px) */
@media (max-width: 1024px) {
  .card-grid7 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    gap: 30px;
  }

  .card7 {
    width: 100%; /* Cards will take full width */
    padding: 20px;
  }

  .card7 img {
    width: 40px;
    height: 40px;
  }

  .card7 p {
    font-size: 0.85em;
  }
}

/* For mobile devices (screen width <= 768px) */
@media (max-width: 768px) {
  .card-grid7 {
    grid-template-columns: repeat(2, 1fr);  /* Single column on mobile */
    gap: 20px;
  }

  .card7 {
    width: 95%;
  }

  .card7 img {
    width: 50px;
    height: 50px;
  }

  .card7 p {
    font-size: 0.8em;
  }
}

/* For very small mobile devices (screen width <= 480px) */
@media (max-width: 480px) {
  .card-grid7 {
    grid-template-columns: 1fr; /* Single column */
    gap: 20px; /* Smaller gap between cards */
    justify-items: center; 
    margin-left: 10px; /* Center the cards */
  }

  .card7 {
    width: 100%; /* Full width for mobile view */
    height: auto; /* Height adjusts based on content */
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center the image and text */
    text-align: center; /* Center the text */
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Softer shadow */
  }

  .card7 img {
    width: 60px; /* Adjust image size */
    height: 60px;
    margin-bottom: 10px; /* Space between image and text */
  }

  .card7 p {
    font-size: 0.85em; /* Adjust font size */
  }

  .content7 h1 {
    font-size: 1.25em; /* Smaller heading font size */
  }
}
